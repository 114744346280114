export const ConfigurationManager = {
    REACT_FEATURE_BU: "PRE",
    REACT_APP_LOCAL_SERVICE_URL: "https://ams-dev2.integritymarketing.com",
    REACT_APP_CLIENT_ID: "b1f6593c-8e6f-479e-ad41-e323794125b2",
    REACT_APP_PROD_CLIENT_ID: "b2c20358-247b-4628-b4c0-9f0e3bb2ee8f",
    REACT_APP_QA_CLIENT_ID: "9baeb1be-2e00-4020-9561-71dd719dd49a",
    REACT_APP_STAGE_CLIENT_ID: "a54dc602-bcfb-4a9c-8186-7da518573e3b",
    REACT_APP_UAT_CLIENT_ID: "a934267e-56a7-4440-9b03-3544f2605708",
    microServices: {
        releaseNotes: "https://ams-pre-dev.integritymarketing.com",
        snowflakeQuery: "https://ams-pre-dev.integritymarketing.com",
        inviteService: "https://ams-pre-dev.integritymarketing.com",
    },
};

//Auth0 key configuration
export const Auth0ConfigurationManager = {
    auth0ApiKeys: [
        {
            env: ["dev", "localhost", "feature"],
            domain: "dev-zaexgxff.us.auth0.com",
            clientid: "wtkeXlAwBd1r1um9iJSJTPzefYKKujLV",
            audience: "https://ServiceAuth.com",
        },
        {
            env: ["qa"],
            domain: "img-connect-qa.us.auth0.com",
            clientid: "uVm2oiMN9vqPbTNLy8swqSFc9UvScT6d",
            audience: "https://ServiceAuth.com",
        },
        {
            env: ["uat"],
            domain: "img-connect-uat.us.auth0.com",
            clientid: "Jf30hJKXygNFkJnOZ8qxbQBqnfUJfxgs",
            audience: "https://ServiceAuth.com",
        },
        {
            env: ["stage"],
            domain: "img-connect-stage.us.auth0.com",
            clientid: "nNz1x5K6UB0WRZYftlRZqh9YyAmeRaNI",
            audience: "https://ServiceAuth.com",
        },
        {
            env: ["demo"],
            domain: "img-connect-demo.us.auth0.com",
            clientid: "YrsJf1mvLssfy6l2qk0XI2RQysanIYGe",
            audience: "https://ServiceAuth.com",
        },
        {
            env: ["prod"],
            domain: "img-connect-prod.us.auth0.com",
            clientid: "PJAGqG8izJKe8QgclglMDDUQaWyyqxKX",
            audience: "https://ServiceAuth.com",
        },
    ],
};

export enum Features {
    GOALS = "Goals",
    DEBT = "Debt",
    CONTRACTMANAGEMENT = "Contract Management"
}

/**
 * Environment based configuration for the web app
 */
export const envConfigurationManager = {
    localhost: {
        features: [
            { name: Features.GOALS, isEnabled: true },
            { name: Features.DEBT, isEnabled: true },
            { name: Features.CONTRACTMANAGEMENT, isEnabled: true },
        ]
    },
    dev: {
        features: [
            { name: Features.GOALS, isEnabled: true },
            { name: Features.DEBT, isEnabled: true },
            { name: Features.CONTRACTMANAGEMENT, isEnabled: true },
        ]
    },
    feature: {
        features: [
            { name: Features.GOALS, isEnabled: true },
            { name: Features.DEBT, isEnabled: true },
            { name: Features.CONTRACTMANAGEMENT, isEnabled: true },
        ]
    },
    qa: {
        features: [
            { name: Features.GOALS, isEnabled: true },
            { name: Features.DEBT, isEnabled: true },
            { name: Features.CONTRACTMANAGEMENT, isEnabled: true },
        ]
    },
    uat: {
        features: [
            { name: Features.GOALS, isEnabled: false },
            { name: Features.DEBT, isEnabled: true },
            { name: Features.CONTRACTMANAGEMENT, isEnabled: false },
        ]
    },
    stage: {
        features: [
            { name: Features.GOALS, isEnabled: false },
            { name: Features.DEBT, isEnabled: true },
            { name: Features.CONTRACTMANAGEMENT, isEnabled: false },
        ]
    },
    demo: {
        features: [
            { name: Features.GOALS, isEnabled: false },
            { name: Features.DEBT, isEnabled: false },
            { name: Features.CONTRACTMANAGEMENT, isEnabled: false },
        ]
    },
    prod: {
        features: [
            { name: Features.GOALS, isEnabled: false },
            { name: Features.DEBT, isEnabled: false },
            { name: Features.CONTRACTMANAGEMENT, isEnabled: false },
        ]
    },
};
