import { observable, action, computed } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import Collection from "../../infrastructure/CollectionHelper";
import RouteList from "../../infrastructure/RouteList";
import { CustomerLookupViewModel } from "../components/CustomerLookupViewModel";
import { AgentLookupViewModel } from "../components/AgentLookupViewModel";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator/AbstractValidator";
import IMASLog from "../../infrastructure/IMASLog";
import loggedInUser from "../../infrastructure/Auth0Authenticator";
import { CreateUpdateTaskModel, TaskModel } from "../../services/TaskService";
import TaskStore from "../../store/TaskStore";
import Utils from "../../infrastructure/Utils";
import ContractsStore from "../../store/ContractsStore";
import InsuredStore from "../../store/InsuredStore";
import UserStore from "../../store/IdentityStore";
import { UserLookupViewModel } from "../components/UserLookupViewModel";
import { UserLookupListModel } from "../../services/IdentityService";

import RouteParamDetails from "../../infrastructure/RouteParamDetails";
import UserContext from "../../infrastructure/UserContext";
import ProducerSearchStore from "../../store/ProducerSearchStore";
import { AgentListModel } from "../../services/ProducerSearchService";
import IdentityStore from "../../store/IdentityStore";
import { Permission } from "../../infrastructure/enum/Permission";
import DocumentStore from "../../store/DocumentStore";
import ProducerStore from "../../store/ProducerStore";
import { googleTagManager } from "../../infrastructure/tracking";
import PageContext from "../../infrastructure/PageContext";
import { Constants } from "../../infrastructure/enum/Constants";
import { CustAttrToEntityModel } from "../../services/CustomAttributeService";
import { CustomAttributesComponentViewModel } from "../../views/customers/CustomAttributesComponentViewModel";
import CustomAttributeStore from "../../store/CustomAttributeStore";
import { closeToasterButtonTemplate } from "../../views/components/controls/IMASTemplates";
import { toast } from "react-toastify";
import { utimes } from "fs";
import "react-toastify/dist/ReactToastify.css";

export class AddTaskViewModel implements ViewModel {
    get CanClose(): boolean {
        throw new Error("Method not implemented.");
    }
    get CanRoute(): boolean {
        return true;
    }
    get IsLoaded(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoading(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsSaveNoteDisabled(): boolean {

        if (this.selectedAgent === ""  &&   this.selectedCustomer === ""   )
        {
            return false;
        }
       if(this.hasAgentInvalidValue==false && this.hasInsuredInvalidValue==false)
        
       return false;

        return (this.hasAgentInvalidValue || this.hasInsuredInvalidValue);                                  
    }
    Close(): void {
        throw new Error("Method not implemented.");
    }

    @observable taskId: number = 0;
    @observable agentId: number | undefined;
    @observable userId: number | undefined;
    @observable customerId: number | undefined;
    @observable orderId: number | undefined;
    @observable companyId: number | undefined;
    @observable taskTitle: string = "";
    @observable taskDate: Date | undefined = new Date();
    @observable description: string = "";
    @observable isEdit: boolean = false;
    @observable message: string = "";
    @observable selectedCompany: string = "";
    @observable selectedUser: string = "";
    @observable selectedUserId: string = "";
    @observable createdDate: Date | undefined = new Date();
    @observable completeDate: Date | undefined = new Date();
    @observable isComplete: boolean = false;
    @observable isSaveNotes: boolean = false;
    @observable agentInput: string | null = null;
    @observable customerInput: string | null = null;
    @observable
    CustomerLookupViewModel: CustomerLookupViewModel = new CustomerLookupViewModel();
    @observable
    AgentLookupViewModel: AgentLookupViewModel = new AgentLookupViewModel();
    @observable FilteredAgentsList = new Collection<AgentListModel>();
    @observable FilteredCustomersList = new Collection<CustomerLookupListModel>();
    @observable
    UserLookupViewModel: UserLookupViewModel = new UserLookupViewModel();
    @observable FilteredUsersList = new Collection<UserLookupListModel>();

    @observable title: string = "Add Task";
    @observable taskNotes: string = "";
    @observable selectedAgent: string = "";
    @observable selectedCustomer: string = "";
    @observable selectedCustomerId: string = "";
    @observable assignTo: string = loggedInUser.displayName;
    @observable taskDetailModel: TaskModel | undefined;
    @observable isException: boolean = false;
    @observable isSuccess: boolean = false;
    @observable isTaskServiceApiCall: boolean = true;
    @observable isLoading: boolean = false;
    @observable startIndex: number = 0;
    @observable rows: number = 25;
    @observable agentsSearchList: number = 0;
    @observable customersSearchList: number = 0;
    @observable usersSearchList: number = 0;
    @observable canCompleteTask: boolean = false;
    @observable FilteredDocumentList = new Collection<any>();
    @observable documentList = new Collection<any>();
    @observable selectedDocument: string = "";
    @observable selectedDocumentId: number = 0;
    @observable exceptionMessage: any;
    @observable isDocumentEnable: boolean = true;
    @observable documentTooltip: string = "Select an agent to attach a document";
    @observable hasAgentInvalidValue: boolean = false;
    @observable hasInsuredInvalidValue: boolean = false;
    @observable agentIdCheck: number = 0;
    @observable response: any = "";

    //custom attribute states    
    @observable addAttributesModal: boolean = false;
    @observable CustomAttributesComponentViewModel: CustomAttributesComponentViewModel = new CustomAttributesComponentViewModel();
    @observable isCustomAttrActive: boolean = false;
    @observable customAttributeList: any = [];
    @observable isValid = true;
    @observable attributesScreenPath: number = 7;
    @observable attributesScreenPathId: number = 7;
    @observable custAttrToEntityList = new Collection<CustAttrToEntityModel>();
    @observable isCustAttrWarning: boolean = false;
    @observable isValidationToasterVisible: boolean = false;
    @observable contractEntityID: number = 0;
    @observable taskCreatedId: number = 0;

    @action Load = async (id: number, taskId: number | undefined) => {
        this.isCustAttrWarning = false;
        this.agentIdCheck = id;
        this.ResetValidate();
        this.resetAllFields();
        this.agentId = id;
        if (taskId) {
            this.taskId = taskId;
            this.isEdit = true;
            this.title = "Edit Task";
        } else {
            this.message = "";
            this.taskId = 0;
            this.isEdit = false;
            this.title = "Add Task";
        }
        if (RouteParamDetails.id && RouteParamDetails.id > 0 && this.agentId === RouteParamDetails.id)
            this.documentTooltip = "";

        this.selectedUserId = UserContext.userId
            ? UserContext.userId.toString()
            : "0";
        await this.loadTaskDetails();
        this.isSaveNotes = !taskId && this.selectedAgent && Utils.hasUserPermission(Permission.DefaultSaveToNotes)
        this.setIsSuccess(false);
        this.canCompleteTask = false;
        
    };

    protected async checkPermissions(): Promise<void> {
        localStorage.removeItem("CustomAttribute");
        // get Custom attribute screen permission
        var res = await this.getCustomAttributePermissionByScreen();
        if (res) {
            let data = res.filter(
                (x) =>
                    x.path === Permission.AddEditTaskCustomAttribute && x.active === true
            );
            this.isCustomAttrActive = data.length > 0 ? true : false;

            var attributes = await this.getCustomAttributesByScreen(7);
            if (attributes) {
                this.customAttributeList = attributes;
            }
            this.attributesScreenPathId = 7;
        }
    };

    @action createUpdateAttributes = async () => {
        let data: any = [];
        (this.customAttributeList || []).map((item: any) => {
            item.attrValue = item.dataTypeId == 6 ? (item.attrValue ? Utils.getDateInFormat(new Date(item.attrValue)) : "") : item.attrValue;
            data.push({
                customAttributeScreenId: item.scrnCustAttrId,
                entityId: this.contractEntityID,
                value: item.attrValue + "",
            });
        });
        
        return data;
    };

    Route = async (currentRoute: RouteList): Promise<void> => {
        var routeParams = JSON.parse(JSON.stringify(currentRoute.params));
        RouteParamDetails.agentId = routeParams.agentId;
        this.agentId = routeParams.agentId;
        localStorage.setItem("agentId", "" + this.agentId);
    };

    @observable priority: string = "Low";
    @observable priorityList: { label: string; value: string }[] = [
        { label: "Low", value: "Low" },
        { label: "Medium", value: "Medium" },
        { label: "High", value: "High" },
    ];

    @observable companyList = [{ label: "NONE", value: "NONE" }];
    @computed
    get isDialogVisible(): boolean {
        return this.CustomerLookupViewModel.isDialogVisible;
    }

    @action async getCustomAttributePermissionByScreen() {
        try {
            var res = await CustomAttributeStore.getAllScreens();
            return res;
        }
        catch (e) {
            throw e;
        }

    }

    @action async getCustomAttributesByScreen(screenId: number) {
        try {
            var res = await CustomAttributeStore.getAllAttributesByScreen(screenId, this.taskId);
            return res;
        }
        catch (e) {
            throw e;
        }

    }

    @action setSelectedDocument(value: any) {
        this.selectedDocument = value;
    }

    @action downloadDocumentLink = async (docId: number, filename: string) => {
        this.isLoading = true;
        this.isException = false;
        this.exceptionMessage = {};
        try {
            var result = await DocumentStore.downloadFiles(docId, 0, true);
            if (result != null) {
                if (result.data) {
                    Utils.downloadDocumentFile(
                        result.data,
                        result.fileName !== null && result.fileName !== undefined
                            ? result.fileName
                            : filename,
                        result.headers ? result.headers["content-type"] : result.data.type
                    );
                }
            }
        } catch (e: any) {
            this.isException = true;
            this.exceptionMessage = e.response;
        }
        this.isLoading = false;
    };

    @action filterDocuments = async (value: string) => {
        let result = await DocumentStore.getAgentDocuments(
            this.agentId ? this.agentId : parseInt("" + localStorage.getItem("currentAgentId")) || 0,
            0,
            0,
            this.selectedDocument,           
            0,
            100,
            "id",
            false,
            Utils.hasUserPermission(Permission.ManagePrivateAgentDocuments) ? null : false
        );
        var noAgent: AgentListModel = { id: 0 };
        if (result && result.recordCount && result.data) {
            let self = this;
            result.data =
                self.documentList.values &&
                result.data.filter((i) => {
                    return (
                        self.documentList.values.find(
                            (j) =>
                                j.documentName == i.documentName &&
                                j.fileType == i.fileType &&
                                !j.isDeleted
                        ) == null
                    );
                });
            result.recordCount = (result.data && result.data.length) || 0;
        }
        if (result !== null) {
            if (result.recordCount) {
                if (result.data) {
                    result.data.splice(0, 0, {
                        id: -1,
                    });
                    this.FilteredDocumentList.values = result.data;
                    var element = document.querySelector(".p-autocomplete-panel");
                    setTimeout(() => {
                        if (element) {
                            element.scrollTo(0, 0);
                        }
                    }, 10);
                }
            } else {
                this.FilteredDocumentList.values = [noAgent];
                this.selectedDocument = "";
            }
        }
    };

    @action mapListItemAndSort(listObjects: any) {
        if (listObjects) {
            var sortList = listObjects.sort(Utils.compareListItem);
            sortList.forEach((element: any) => {
                element.label = element.text;
            });
            return sortList;
        }
        else {
            return [];
        }
    }

    @action loadCompanies = async () => {
        try {
            this.isLoading = true;
            var carriers = await ContractsStore.getAllCompanyLookup();
            if (carriers) {
                this.companyList = this.mapListItemAndSort(carriers)
                if (this.isEdit && this.taskDetailModel)
                    await this.setCompanyName(
                        this.taskDetailModel.companyId
                            ? "" + this.taskDetailModel.companyId
                            : ""
                    );
            }
            this.isLoading = false;
        } catch (e) {

        }
    };
    @action setSelectedAgentName = async () => {
        try {
            this.isLoading = true;
            if(this.agentId != null && this.agentId != undefined)
            {
                var agentInfo = await ProducerStore.getAgentProfileInfoById(this.agentId);
                if (agentInfo && agentInfo.name) {

                    let nameArray = agentInfo.name.split(" ");
                    if (nameArray.length > 1) {
                        this.selectedAgent = nameArray[1] + ", " + nameArray[0];
                    } else {
                        this.selectedAgent = nameArray[0];
                    }  
                }
            }          
            this.isLoading = false;
        } catch (e) {

        }
    };

    @action resetAllFields() {
        this.taskTitle = "";
        this.taskDate = new Date();
        this.priority = "Low";
        this.selectedAgent = "";
        this.selectedCustomer = "";
        this.selectedCustomerId = "";
        this.selectedUser = "";
        this.selectedCompany = "";
        this.companyList = [];
        this.description = "";
        this.isComplete = false;
        this.isSaveNotes = false;
        this.isTaskServiceApiCall = true;
        this.documentList.values = [];
        this.selectedDocument = "";
        this.selectedDocumentId = 0;
        this.isCustomAttrActive = false;        
        PageContext.isCustomAttribute = false;
    }

    getBooleanValue(value: boolean | undefined) {
        return value ? value : false;
    }
    @computed
    get isAgentSearchDialogVisible(): boolean {
        return this.AgentLookupViewModel.isDialogVisible;
    }
    @action setSelectDate(value: Date) {
        this.createdDate = value;
    }
    @action setSelectedCompany(value: string) {
        this.selectedCompany = value;
    }
    @action setSelectedUser(userData: any) {
        this.selectedUser = userData;
    }
    @action setSelectedUserId(id: any) {
        this.selectedUserId = id;
    }
    @action setSelectedCustomerId(id: any) {
        this.selectedCustomerId = id;
    }
    @action setSelectedAgentId(id: any) {
        this.agentId = id;
    }
    @action setSelectedAgent(value: string) {
        this.selectedAgent = value;
        if (value === "" && this.taskDetailModel) {
            this.agentId = 0;
            this.taskDetailModel.agentId = 0;
        }
    }
    @action setSelectedCusomer(value: string) {
        this.selectedCustomer = value;
        if (value === "" && this.taskDetailModel) {
            this.selectedCustomerId = "";
            this.taskDetailModel.customerId = 0;
        }
    }
    @action setTitle(value: string) {
        this.taskTitle = value;
    }
    @action setAssignTo(value: string) {
        this.assignTo = value;
    }
    @action setTaskDate(value: Date | undefined) {
        this.taskDate = value;
    }
    @action setPriority(value: string) {
        this.priority = value;
    }
    @action setTaskNotes(value: string) {
        this.description = value;
    }
    @action setCreateDate(value: Date | undefined) {
        this.createdDate = value;
    }
    @action setDialogVisibility(value: boolean) {
        this.CustomerLookupViewModel.isDialogVisible = value;
    }
    @action setAgentSearchDialogVisibility(value: boolean) {
        this.AgentLookupViewModel.isDialogVisible = value;
    }
    @action setIsComplete(value: boolean) {
        this.isComplete = value;
    }
    @action setSaveNotes(value: boolean) {
        this.isSaveNotes = value;
    }
    @action setCompanyName = async (value: string) => {
        this.selectedCompany = value;
    };
    @action setIsException(value: boolean) {
        this.isException = value;
    }
    @action setIsSuccess(value: boolean) {
        this.isSuccess = value;
    }
    @action searchIntervals = (text: string, value: string) => {
        if (value === "agent") {
            if (!text) {
                this.AgentLookupViewModel.SelecetedAgent = null;
                this.agentInput = null;
            } else this.agentInput = text;
        } else {
            if (!text) {
                this.CustomerLookupViewModel.SelecetedAgent = null;
                this.customerInput = null;
            } else this.customerInput = text;
        }
    };
    @action searchAgent = () => {
        this.AgentLookupViewModel.isDialogVisible = true;
    };
    @action searchCustomer = () => {
        this.CustomerLookupViewModel.isDialogVisible = true;
    };
    @action
    public ResetValidate = async () => {
        return this.ErrorModel.ResetValidation(this);
    };
    @action
    public Validate = async () => {
        return this.ErrorModel.Validate(this);
    };
    @action goBack() {
        setTimeout(() => {
            Utils.goBack();
        }, Utils.timeDelay_Success());
    }
    @action loadTaskDetails = async () => {
        this.isLoading = true;
        this.isException = false;
        if (this.isEdit) {
            try {
                this.taskDetailModel = await TaskStore.getTaskDetails(this.taskId);
            } catch (error) {
                IMASLog.log("error while fetching task details " + error);
                this.isException = true;
                this.isLoading = false;
            }
            if (this.taskDetailModel) {
                this.selectedUserId = (this.taskDetailModel.userId || 0).toString();
                this.taskTitle = "" + this.taskDetailModel.task;
                var date = Utils.getDateInFormat(
                    this.taskDetailModel.taskDate
                        ? this.taskDetailModel.taskDate
                        : new Date()
                );
                this.setTaskDate(new Date(date));                
                this.description = this.taskDetailModel.description || "";
                this.isComplete = this.getBooleanValue(this.taskDetailModel.complete);
                this.setPriority(
                    this.taskDetailModel.priority === 3
                        ? "High"
                        : this.taskDetailModel.priority === 2
                            ? "Medium"
                            : "Low"
                );
                await this.loadCompanies();
                this.selectedCompany = "" + this.taskDetailModel.companyName;
                if (this.taskDetailModel.companyId) {
                    this.selectedCompany = '' + this.taskDetailModel.companyId;
                }
                this.selectedUser = "" + this.taskDetailModel.userName;
                this.selectedCustomer =
                    this.taskDetailModel.customerName === null
                        ? ""
                        : "" + this.taskDetailModel.customerName;
                this.selectedAgent =
                    this.taskDetailModel.agentName === null
                        ? ""
                        : "" + this.taskDetailModel.agentName;

                this.isDocumentEnable = this.selectedAgent  == "" ? true : false;
                this.documentList.values = this.taskDetailModel.documentAttachmentList || [];
            }
        }
        else {
            if (this.agentId !== 0) {
                 await this.setSelectedAgentName();
                this.isDocumentEnable = false;
            }
            var loginuserName = loggedInUser.displayName.split(" ");
            this.selectedUser =
                "" + loginuserName[loginuserName.length - 1] + ", " + loginuserName[0];

            //call api to get userid by aduserid
            var result = await IdentityStore.getUserByAdGuid(loggedInUser.adUserId);
            if (result) {
                this.selectedUserId = result.id ? result.id.toString() : "0";
            }
            await this.loadCompanies();
        }

        if(Utils.hasUserPermission(Permission.PrivateCustomAttribute) || Utils.hasUserPermission(Permission.PublicCustomAttribute))
        {
            await this.checkPermissions();
        }

        this.isLoading = false;
    };

    @action deleteDocument(rowId: any, mappingId: any) {
        let document: any;
        if (rowId && rowId > 0 && mappingId == 0) {
            this.documentList.values = this.documentList.values.filter(
                (item: any) => {
                    return item.rowId != rowId;
                }
            );
        } else if (mappingId && mappingId > 0) {
            document = this.documentList.values.filter((item: any) => {
                return item.mappingId == mappingId;
            });

            if (document && document.length > 0) {
                document[0].isDeleted = true;
            }
        }
    }

    @action hideValidationToaster = () => {
        this.isValidationToasterVisible = false;
        toast.dismiss("validation-toaster");
    };

    @action validateCustomAttributes = () => {
        let isValid2: boolean = true;
        if (this.customAttributeList) {
            var mandatoryCustomAttributeData = (
                this.customAttributeList || []
            ).filter(
                (x: any) =>
                    x.isMandatory == true &&
                    (x.attrValue == null || x.attrValue == "" || x.attrValue == undefined)
            );
            if (
                mandatoryCustomAttributeData != null &&
                mandatoryCustomAttributeData.length > 0 &&
                (Utils.hasUserPermission(Permission.PrivateCustomAttribute) ||
                    Utils.hasUserPermission(Permission.PublicCustomAttribute))
            ) {
                isValid2 = false;
                this.isCustAttrWarning = true;
                // this.isCustAttrWarning && window.scrollTo(0, document.body.scrollHeight);
                toast.warning(`Please fill all required fields for custom attributes`, {
                    position: toast.POSITION.TOP_RIGHT,
                    closeOnClick: false,
                    closeButton: closeToasterButtonTemplate(this.hideValidationToaster),
                    className: "validation-toaster contract",
                    draggable: false,
                    toastId: "validation-toaster",
                });
                return;
            } else {
                toast.dismiss("validation-toaster");
                isValid2 = true;
                this.isCustAttrWarning = false;
            }
        }
        return isValid2;
    };

    @action
    public Submit = async () => {
        if (!(await this.Validate()) && await this.validateCustomAttributes()) {
            if (this.isComplete) {
                this.canCompleteTask = true;
            } else {
                if (this.isTaskServiceApiCall) {
                    this.isTaskServiceApiCall = false;
                    await this.saveTask();
                }
            }
        }
    };
    private async saveTask() {
        PageContext.isMessageVisible = false;
        this.isLoading = true;
        try {            
            var taskdto: CreateUpdateTaskModel = {};
            taskdto.id = this.taskId;
            taskdto.userId = Number(this.selectedUserId);
           // taskdto.agentId = this.agentId ? this.agentId : 0;
           if (this.agentId) {
                if (this.agentId == this.agentIdCheck) {
                    taskdto.agentId = this.agentId;
                } else {
                    taskdto.agentId = this.agentIdCheck == 0 ? this.agentId : this.agentIdCheck;
                }
            } else {
                taskdto.agentId = 0;
            }
            taskdto.customerId = Number(this.selectedCustomerId);
            taskdto.orderId = this.orderId = 0;
            taskdto.taskDate = this.taskDate;
            taskdto.task = this.taskTitle;
            taskdto.description = this.description;
            taskdto.complete = this.isComplete;
            if (!this.isComplete) {
                taskdto.completedByUserId = 0;
            }
            else {
                taskdto.completedByUserId = UserContext.userId;
            }

            taskdto.priority =
                this.priority === "High" ? 3 : this.priority === "Medium" ? 2 : 1;
            taskdto.dateCreated = this.createdDate;
            if (this.selectedCompany !== "") {
                taskdto.companyId = this.selectedCompany
                    ? Number(this.selectedCompany)
                    : taskdto.companyId;
            } else {
                taskdto.companyId = undefined;
            }
            taskdto.dateCompleted = this.completeDate;
            taskdto.saveNotes = this.isSaveNotes ? this.isSaveNotes : false;
            taskdto.documentAttachmentList = this.documentList.values || null;
            
            if (Utils.hasUserPermission(Permission.PrivateCustomAttribute) ||
                Utils.hasUserPermission(Permission.PublicCustomAttribute)) {
                taskdto.customAttributes = await this.createUpdateAttributes();
            } 

            if (this.isEdit) {
                if (this.taskDetailModel) {
                    taskdto.id = this.taskDetailModel.id
                }
                taskdto.userId = taskdto.userId
                    ? taskdto.userId
                    : this.taskDetailModel
                        ? Number(this.taskDetailModel.userId)
                        : 0;
                if (this.selectedAgent !== "")
                    taskdto.agentId = taskdto.agentId
                        ? taskdto.agentId
                        : this.taskDetailModel
                            ? Number(this.taskDetailModel.agentId)
                            : 0;
                if (this.selectedCustomer !== "")
                    taskdto.customerId = taskdto.customerId
                        ? taskdto.customerId
                        : this.taskDetailModel
                            ? Number(this.taskDetailModel.customerId)
                            : 0;
                await TaskStore.editTask(taskdto.agentId, taskdto);
                if (this.isComplete) {
                    googleTagManager.trackAction("task_completed", { feature: "Task", user_id: UserContext.userId, task_id: taskdto.id });
               }
                IMASLog.log("edit task is successfull..");
            }
            else {
                this.taskCreatedId = await TaskStore.addTask(taskdto.agentId, taskdto);
                googleTagManager.trackAction("task_created", { feature: "Task", user_id: UserContext.userId, agent_id: taskdto.agentId });
                IMASLog.log("add task is successfull..");
            }

            this.contractEntityID = this.isEdit ? this.taskId : this.taskCreatedId;
            this.isLoading = false;
            Utils.goBack();

        } catch (error) {
            this.isLoading = false;
            this.isException = true;
            this.isTaskServiceApiCall = true;
            IMASLog.log("exception: " + error);
        }
    }

    @action async completeTask() {
        await this.saveTask();
        this.setCompleteTaskConfirm(false);
    }
    @action setCompleteTaskConfirm(value: boolean) {
        this.canCompleteTask = value;
    }
    @action getCityState = (agent: AgentListModel) => {
        if (agent.city && agent.state) return agent.city + ", " + agent.state;
        else if (agent.city && !agent.state) return agent.city;
        else if (!agent.city && agent.state) return agent.state;
        else return "";
    };
    @action searchAgents = async (value: string) => {
        let result = await ProducerSearchStore.getAgentsLookup(1,
            value,
            0,
            20,
            undefined,
            undefined
        );
        var noAgent: AgentListModel = { id: 0 };
        if (result !== null) {
            if (result.recordCount) {
                this.agentsSearchList = result.recordCount;
                if (result.data) {
                    this.FilteredAgentsList.values = result.data;
                }
            } else {
                this.agentsSearchList = 0;
                this.FilteredAgentsList.values = [noAgent];
                this.selectedAgent = "";
            }
        } else {
            this.agentsSearchList = 0;
            this.FilteredAgentsList.values = [noAgent];
            this.selectedAgent = "";
        }
        this.isLoading = false;
        setTimeout(() => { }, 500);
    };
    @action searchCustomers = async (value: string) => {
        IMASLog.log("search customers ..");
        var noCustomer: CustomerLookupListModel = { id: 0 };
        var searchTypeVal = 1;
        let isnum = /^\d+$/.test(value);
        if (isnum) { searchTypeVal = 6 };

        let result = await InsuredStore.getCustomerList(searchTypeVal,
            value,
            0,
            100,
            undefined,
            undefined
        );
        if (result !== null) {
            if (result.recordCount) {
                this.customersSearchList = result.recordCount;
                if (result.data) {
                    result.data.splice(0, 0, {
                        id: -1,
                    });
                    this.FilteredCustomersList.values = result.data;
                }
            } else {
                this.customersSearchList = 0;
                this.FilteredCustomersList.values = [noCustomer];
                this.selectedCustomer = "";
            }
        } else {
            this.customersSearchList = 0;
            this.FilteredCustomersList.values = [noCustomer];
            this.selectedCustomer = "";
        }
        this.isLoading = false;
        setTimeout(() => { }, 500);
    };
    @action searchUsers = async (value: string) => {
        IMASLog.log("search users ..");
        var noUser: UserLookupListModel = { id: 0 };
        let result = await UserStore.getUserList(value, 0, 100);

        if (result !== null && result.data) {
            if (result.recordCount) {
                this.usersSearchList = result.recordCount;
                if (result.data) {
                    this.FilteredUsersList.values = result.data;
                }
            } else {
                this.usersSearchList = 0;
                this.FilteredUsersList.values = [noUser];
                this.selectedUser = "";
            }
        } else {
            this.usersSearchList = 0;
            this.FilteredUsersList.values = [noUser];
            this.selectedUser = "";
        }
        this.isLoading = false;
    };


    // custom attribute methods
    @action handleAttributesModal = (value: boolean) => {
        this.CustomAttributesComponentViewModel.disableRadioInitial = true;
        this.addAttributesModal = value;
        setTimeout(async () => {
            value && this.CustomAttributesComponentViewModel.loadExistingAttributes(this.attributesScreenPathId);
            !value && this.CustomAttributesComponentViewModel.resetAttributes();
        }, 900);
    }

    @action AttributeSave = (type: number) => {
        let data = this.CustomAttributesComponentViewModel.saveAttribute(type);
        this.addAttributesModal = false;

        setTimeout(async () => {
            var attributes = await this.getCustomAttributesByScreen(this.attributesScreenPath || 7);
            if (attributes) {
                this.customAttributeList = attributes;
            }
            this.CustomAttributesComponentViewModel.resetAttributes();
        }, 900);
    }

    @observable
    public ErrorModel = new ErrorModel(new Validator());
}

class Validator extends AbstractValidator<AddTaskViewModel> {
    public constructor() {
        super();
        this.validateIfDate((input) => input.taskDate)
            .isNotEmpty()
            .withFailureMessage("Task Date is required");
        this.validateIfString((input) => input.taskTitle)
            .isNotEmpty()
            .isNotEqualTo("NONE")
            .withFailureMessage("Title is required.");
        this.validateIfString(input => input.selectedUser)
            .isNotEmpty()
            .withFailureMessage("Assigned To is required");
    }

}

export interface CustomerLookupListModel {
    id?: number;
    firstName?: string;
    lastName?: string;
    dob?: Date;
    city?: string;
    state?: string;
    zip?: string;
}
