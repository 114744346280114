export enum Alert {
  success = "success",
  danger = "danger",
  warning = "warning",
  info = "info",
}
export enum MessageType {
  Toaster = 0,
  ProgressBar = 1,
  Notification = 2,
  SMS = 3,
}

export enum PageMode {
  Add,
  Edit,
  Update,
  Delete,
  View,
  Clone,
}

export enum ContactType {
  PhoneNumber = "Phone Number",
  Email = "Email Address",
  TextMessage = "Text Message",
  Name = "Name",
}

export enum PhoneType {
  Home = "Home",
  Mobile = "Mobile",
  Work = "Work",
  Others = "Others",
  Other = "Other",
}
export enum EmailType {
  Work = "Work",
  Personal = "Personal",
  Other = "Other",
  Others = "Others",
}
export enum AlternativePointOfContactType {
  Primary = "Primary",
  Secondary = "Secondary",
}

export enum ContactTypeValue {
  PhoneNumber = 3,
  Email = 1,
  TextMessage = 2,
  Name = 4,
}
export enum PhoneTypeValue {
  Home = 3,
  Mobile = 1,
  Work = 2,
  Others = 4,
  Other = 4,
}
export enum EmailTypeValue {
  Work = 1,
  Personal = 2,
  Other = 3,
  Others = 3,
}
export enum AlternativePointOfContactTypeValue {
  Primary = 1,
  Secondary = 2,
}

export enum AppMenuItems {
  Home = "Home",
  Agents = "Agents",
  bookOfBusiness = "Book of Business",
  universalUpload = "Universal Upload",
  contractManagement ="Contracts",
  rtsReview="RTS Review",
  Insured = "Insured",
  Tasks = "Tasks",
  Documents = "Documents",
  Dialer = "Dialer",
  Workflow = "Workflow",
  Goals = "Goals",
  Leads = "Leads",
  Supplies = "Supplies",
  SelfServiceReports = "Reports",
  Reports = "Reports",
  carrierManagement = "Carriers",  
  Administration = "Administration",
  UITemplate = "UI Template",
  NeedHelp = "Need Help",
  TermsOfUse = "Terms of Use"
}
export enum ErrorTypes {
  NoAccess = "No Access",
  InternalServerError = "Internal Server Error",
}
export enum AgentSearch {
  LastName = "1",
  Agent = "10",
  AgentId = "2",
  ContactOptions = "9",
  Dba = "5",
  Email = "8",
  Npn = "6",
  Phone = "7",
  Ssn = "3",
  Tin = "4",
  FirstName = "11",
}

export enum UserSearch {
  LastName = "1",
  FirstName = "2",
  Team = "3",
}

export enum UserStatus {
  Inactive = "0",
  Active = "1",
  All = "2",
}
export enum DocType {
  Document,
  DMS,
  Customer,
  WorkFlow,
}

export enum Role {
  Add = "add",
  Edit = "edit",
  Clone = "clone",
  AddTitle = "Add Role",
  EditTitle = "Edit Role",
  CloneTitle = "Clone Role",
  DeleteTitle = "Delete Role",
}

export enum Team {
  Add = "add",
  Edit = "edit",
  Delete = "delete",
  AddTitle = "Create Team",
  EditTitle = "Edit Team",
  DeleteTitle = "Delete Team",
}

export enum CustomReportFilterType {
  None = 0,
  TextBox = 1,
  Dropdown = 2,
  MultiSelectDropdown = 3,
  NumericEntry = 4,
  CheckBox = 5,
  DatePicker = 6,
  AutoComplete = 7,
  TextBoxWithIsEmpty = 8,
  TextBoxWithCondition = 9,
  TextBoxWithRanges = 10,
  TextBoxWithDropdown = 11,
}

export enum CustomReportModalType {
  Create = "Create Custom Report",
  Clone = "Clone Custom Report",
}

export enum CarrierManagement {
  Carrier = "Carrier List",
  CarrierGroup = "Carrier Group List",
}

export enum CarrierManagementTabs {
  Carrier = "Carrier",
  CarrierGroup = "CarrierGroup",
}
export enum CarrierCMTabIndex {
  Details = 0,
  Notes = 1,
  Products = 2,
  Levels = 3,
  Websites = 4,
  Documents = 5,
}

export enum CarrierCM {
  Details = "Details",
  Notes = "Notes",
  Products = "Products",
  Levels = "Levels",
  Websites = "Websites",
  Documents = "Documents",
}

export enum CarrierCMDocuments {
  Documents = "Documents",
  DocumentPackets = "Document Packets",
  
}
export enum CarrierCMDocumentsTabIndex {
  Documents = 0,
  DocumentPackets = 1
}

export enum ManageSubscription {
  Phone = "Phone",
  Email = "Email",
}

export enum ManageSubscriptionIndex {
  Phone = 0,
  Email = 1,
}
export enum PhoneSubscription {
  Text = "View Profiles",
  ActiveText = "View Profiles",
}
export enum AppointmentManagementTabs {
    RTS = "RTS",
    AutoRTS = "AutoRTS",
}
export enum ManageAttributeScreens {
  Statelicense = 1,
  Policy = 2,
  Insured = 3,
  Contract = 4,
  AddAgent = 5,
  EditAgent = 6,
  Tasks = 7
}
export enum PayoutLevelType {
    None = "0",
    Internal = "1",
    Carrier = "2",
}
