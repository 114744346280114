/* import { routes } from "../../router"; */
import { action, observable, toJS } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import Utils from "../../infrastructure/Utils";
import ProducerSearchStore from "../../store/ProducerSearchStore";
import ContractsStore from "../../store/ContractsStore";
import { toast } from "react-toastify";


export class ContractManagementViewModel implements ViewModel {

    @observable gridApi: any;
    @observable gridApiData:any = null;
    @observable gridFilter:boolean = false;
    @observable gridFilterText:string = "";
    @observable gridSortColumn:string = "dateCreated";
    @observable gridSort:string = "asc"; 
    @observable metricData:any = {"allCount":0, "pendingCount":0, "inReviewCount":0, "submittedCount":0, "activeCount":0};
    @observable pageIndex: number = 0;
    @observable pageSize: number = 100;
    @observable totalRecords: number = 0;
    @observable gridOptions:any;

    @observable input_AgentName:string = "";
    @observable input_AgentNameSuggestions:any = null;
    @observable input_AgentSelected:any = 0;


    @observable input_CarrierName:string = "";
    @observable input_CarrierNameSuggestions:any= {
        249:"Integrity",
        252:"Aflac",
        253:"American Amicable",
        254:"Americo",
        255:"Athene",
        256:"Columbian Financial Group",
        257:"Corebridge",
        258:"ETHOS",
        259:"F&G Life",
        260:"Foresters",
        261:"Gerber",
        262:"Global",
        263:"Great Western",
        264:"John Hancock",
        265:"Lafayette",
        266:"Liberty Bankers",
        267:"Mutual of Omaha"
    };
    @observable input_CarrierSelected:any = 0;

    @observable view_InviteAgent:boolean = false;
    @observable view_SendContractRequest:boolean = false;

    @observable btn_sendContract:boolean = true;


    Route = async (currentRoute: RouteList): Promise<void> => {};
    get CanClose(): boolean {
        throw new Error("Method not implemented.");
    }
    get CanRoute(): boolean {
        return true;
    }
    get IsLoaded(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoading(): boolean {
        throw new Error("Method not implemented.");
    }
    Close(): void {
        throw new Error("Method not implemented.");
    }
    @action Load = async () => {
        window.scrollTo(20,0);
        this.gridApiData = [];
        console.log(toJS(Utils.getUserProperties()));
    }

    @action agentSearch = async (search:string) => {
        if (search.length > 2) {
            let results = await ProducerSearchStore.getAgentList(1, search, 0, 10, "lastname", true);
            this.input_AgentNameSuggestions = results.data;
        }
    }
    @action carrierSearch = async () => {
        try {
            let results = await ContractsStore.getAllMasterCarrierLookup();
            this.input_CarrierNameSuggestions = [];
            if(results) {
                results.forEach((x) => {
                    var data = {
                      label: x.text ? x.text : "",
                      value: x.value ? x.value : "",
                    };
                    this.input_CarrierNameSuggestions.push(data);
                  });
            }
        } catch (e) {
            console.log(e);
        }
        /* console.log(toJS(this.input_CarrierNameSuggestions)); */
    }
 
    @action onFilterChanged = async () => {
        let gridFilter = false;
        this.gridApi.getAllDisplayedColumns().map(
            function (col:any) { 
                if(col.isFilterActive()==true) {
                    gridFilter = true;
                }
            }
        );
        this.gridFilter = gridFilter;
    }
    @action resetGrid = async () => {
        /* document.querySelectorAll('.custom-menu-item>div').forEach( btn => btn.classList.remove('active')); */
        this.gridApi.setFilterModel(null);
        /* this.gridApi.applyColumnState({ defaultState: { sort: "null" } }); */
        this.gridApi.resetColumnState();
        this.gridApi.applyColumnState({state:[{colId:"dateCreated",sort:"asc"}]});
    }

    @action onRowsPerPage = async (value: number) => {
        this.pageSize = value;
    };

    @action onPaginationChanged = async (params: any = null, pageIndex: number) => {
        this.pageIndex = pageIndex;
    };
    @action setGridApi = (api: any) => {
        this.gridApi = api;
    };

    @action getMetricData = async () => {
        let _url = Utils.getServiceUrlByEnvironment()+"/api/contract/v1/contractsManagement/GetAllMetrics";
        const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json"
        }
        };
        fetch(_url, requestOptions)
        .then(fetchresponse => fetchresponse.json())
        .then(fetchdata => {
            console.log(fetchdata);
            this.metricData = fetchdata;
        })
        .catch(() => {
            this.metricData = {"allCount":0, "pendingCount":0, "inReviewCount":0, "submittedCount":0, "activeCount":0};
        });
    }
    @action getRowData = async (searchText:string="datecreated=From:2000-01-01To:2050-12-31", pageIndex:number=1, pageSize:number=50) => {
        let _url = Utils.getServiceUrlByEnvironment()+"/api/contract/v1/contractsManagement/GetAllContracts";
        let searchParameter:string = (searchText.includes("=")?searchText:"");
        let fullTextSearch:string = searchParameter===""?searchText:"";
        let sortAscending:boolean = this.gridSort==="asc"?true:false;

        /* console.log(searchParameter); */
        const requestOptions = {
          method: 'POST',
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ 
            "pageIndex": pageIndex,
            "pageSize": pageSize,
            "sortColumn": this.gridSortColumn,
            "sortAscending": sortAscending,
            "fullTextFilter": fullTextSearch,
            "searchParameter": searchParameter
          })
        };
        fetch(_url, requestOptions)
        .then(function(fetchresponse) {
            if(fetchresponse.ok) { return fetchresponse.json(); }
            throw new Error('Something went wrong.');
        })
        .then(fetchdata => { 
            this.gridApiData = fetchdata.data;
            /* this.gridApiData = []; */
            this.totalRecords = fetchdata.recordCount || 0;
            console.log("searchText:"+searchText+" fullTextSearch:"+fullTextSearch+" searchParameter:"+searchParameter);
        })
        .catch(error => {
            this.gridApiData = [];
            console.log(error);
        });
    }

    @action checkForm = async (e:any) => {
        e.preventDefault();
        if(this.input_AgentSelected!==0&&this.input_CarrierSelected!==0) {
            console.log("AgentID="+this.input_AgentSelected+" CarrierID="+this.input_CarrierSelected);
            let _url = Utils.getServiceUrlByEnvironment()+"/api/contract/v1/contractsManagement/SendContractRequest";
            const requestOptions = {
                method: 'POST',
                headers: {
                  "Content-Type": "application/json"
                },
                body: JSON.stringify({ 
                  "agentID":parseInt(this.input_AgentSelected),
                  "carrierID":parseInt(this.input_CarrierSelected),
                  "carrierName":this.input_CarrierName
                })
              };

            fetch(_url, requestOptions).catch(error => {
                console.log(error);
            });


            /* toast.success("Contract invite sent.", {className:"custom-toaster-success", autoClose:4000}); */
            this.input_AgentName = "";
            this.input_AgentSelected = 0;
            this.input_CarrierName = "";
            this.input_CarrierSelected = 0;
            this.view_SendContractRequest=false; 
        } else {
            /* toast.error("All fields are required.", {className:"custom-toaster-error", autoClose:4000}); */
        }
    }


};
    