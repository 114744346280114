import { observable, action, computed } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import Collection from "../../infrastructure/CollectionHelper";
import { MenuItem } from "primereact/components/menuitem/MenuItem";
import { routes } from "../../router";

import TaskStore from "../../store/TaskStore";
import { TaskListModel, ReportFile } from "../../services/TaskService";
import Utils from "../../infrastructure/Utils";
import IMASLog from "../../infrastructure/IMASLog";
import UserContext from "../../infrastructure/UserContext";
import DocumentStore from "../../store/DocumentStore";
import ContractsStore from "../../store/ContractsStore";

export class TasksViewModel implements ViewModel {
  @observable ShowCompleteOption: boolean = false;
  @observable taskCheckboxDisabled: boolean = false;

  @observable IsShowAll: boolean = false;
  @observable ShowAllOption: boolean = false;
  @observable showCompletedOptions = [
    { label: "ALL", value: "" },
    { label: "Open", value: "0" },
    { label: "Completed", value: "1" },
  ];
  @observable assignedToList = new Collection<any>();
  @observable teamMemberList = new Collection<{
    label: string;
    value: string;
  }>();

  @observable createdByUserList = new Collection<{
    label: string;
    value: string;
  }>();

  @observable isShowTeamTasks: boolean = false;
  @observable selectedTeamMember: any = [];
  @observable selectedCreatedByUser: any = [];
  @observable selectedShowCompleteOption: string = "0";
  @observable selectedCarriers: any = [];
  @observable showInactiveUsers: boolean = false;
  @observable carriersList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable showInactiveCarriers: boolean = false;
  @observable carriersInitial = new Collection<{
    label: string;
    value: string;
  }>();
  @observable activeCarriers = new Collection<{
    label: string;
    value: string;
  }>();

  @observable isInitialLoad: boolean = true;
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  @action Load = async () => {
    this.showInactiveCarriers = false;
    this.startIndex = 0;
    this.first = 0;
    this.sortColumn = "taskDate";
    this.sortOrder = false;
    let user = Utils.getUserId() || 0;
    if(this.userId != user){
      this.resetFilters();
    }
    this.userId = user;
    this.noRecordsFound();
    await this.handleLoad();
  };

  @action handleLoad = async () => {
    if (this.isInitialLoad) {
      this.resetAllFields();
      await this.loadDropdowns(false);
      await this.loadCompanies();
    }
    await this.loadTasks();
  };

  @action carriersChange = (value: string) => {
    this.selectedCarriers = value;
  };
  @action handleInactiveCarriers = async (e: any) => {
    this.showInactiveCarriers = e.checked;
    this.carriersList.values = [];
    this.showInactiveCarriers
      ? (this.carriersList.values = this.carriersInitial.values)
      : ((this.carriersList.values = Utils.carriersFilter(
          this.carriersInitial.values
        )),
        (this.activeCarriers.values = this.carriersList.values));
    if (!this.showInactiveCarriers) {
      if (this.selectedCarriers.length == this.carriersInitial.values.length) {
        let tmpArray: any =
          this.activeCarriers.values.map((itm: any) => itm.value) || [];
        this.selectedCarriers = [];
        this.carriersChange(tmpArray);
      }
    }
  };

  @action loadCompanies = async () => {
    try {
      var carriers = await ContractsStore.GetActiveInactiveAllCarrierLookup();
      if (carriers) {
        this.selectedCarriers = [];
        Utils.mapListItemsToDropdown(
          carriers ? carriers : [],
          this.carriersList,
          "None",
          "-1"
        );
        this.carriersInitial.values = this.carriersList.values;
        this.carriersList.values = Utils.carriersFilter(
          this.carriersInitial.values
        );
      }
    } catch (e) {}
  };
  @action updateMyTask = async (id: number) => {
    this.taskId = id;
    routes.editMyTask.push({ taskId: id });
  };
  @action addMyTask = async () => {
    routes.addMyTask.push();
  };

  Route = async (): Promise<void> => {};

  @observable SelectedTasks = [];

  @observable endDate: Date | undefined = new Date();
  @observable isLoading: boolean = true;
  @observable isTaskServiceApiCall: boolean = true;
  @observable isContractingSent: boolean = true;
  @observable startIndex: number = 0;
  @observable startSearchIndex: number = 0;
  @observable agentId: number = 0;
  @observable userId: number = 0;
  @observable taskId: number = 0;
  @observable rows: number = 25;
  @observable first: number = 0;
  @observable TaskList = new Collection<TaskListModel>();
  @observable searchInputValue: string = "";
  @observable searchValue: string | undefined;
  @observable taskDate: any = null;
  @observable taskDateChange: any;
  @observable totalRecords: number = 0;
  @observable isDeleteTaskConfirm: boolean = false;
  @observable isCompleteTaskConfirm: boolean = false;
  @observable isMultipleCompleteTaskConfirm: boolean = false;
  @observable selectedType: string = "0";
  @observable selectedUser: string = "0";
  @observable sortColumn: string = "id";
  @observable sortOrder: boolean = false;
  @observable taskDetailsChangeDetector: number = 1;
  @observable taskCompleteSelection: any = [];

  @computed
  get toDate(): string {
    this.taskDate = Utils.getDateInFormat(this.endDate);
    return Utils.getDateInFormat(this.endDate);
  }
  @observable statusData = [
    { label: "Open", value: "Open" },
    { label: "Close", value: "Close" },
  ];
  @observable selectedStatus: string = "Open";
  @observable actionItems: MenuItem[] = [
    {
      label: "Edit",
      command: () => {
        // this.growl.show({severity:'success', summary:'Updated', detail:'Data Updated'});
      },
    },
  ];
  @action searchTask = async (key: string, text: string) => {
    if (key === "Enter") {
      await this.loadTasks();
    }
  };
  @action goSearch = async () => {
    if (this.isTaskServiceApiCall) {
      this.startIndex = 0;
      this.first = 0;
      this.sortColumn = "taskDate";
      this.sortOrder = false;
      this.isTaskServiceApiCall = false;
      if (
        this.selectedShowCompleteOption == "" ||
        this.selectedShowCompleteOption == "1"
      )
        this.ShowCompleteOption = true;
      else this.ShowCompleteOption = false;
      await this.loadTasks();
      this.isInitialLoad = false;
    }
  };
  @action resetAllFields() {
    this.ShowCompleteOption = false;
    this.startIndex = 0;
    this.first = 0;

    if (this.taskDateChange !== null && this.taskDateChange !== undefined) {
      this.endDate = this.taskDateChange;
      // this.selectedTeamMember = [];
      // this.selectedCreatedByUser = [];
      // this.selectedShowCompleteOption = '0';
      // this.searchInputValue = '';
      // this.searchValue = '';
    } else {
      this.endDate = new Date();
    }

    this.sortColumn = "taskDate";
    this.sortOrder = false;
  }
  @action resetFilters = async () => {
    this.showInactiveCarriers = false;
    this.showInactiveUsers = false;
    if (this.isTaskServiceApiCall) {
      if (this.createdByUserList.values.length > 0) {
        this.selectedCreatedByUser = [];
        for (let item of this.createdByUserList.values) {
          this.selectedCreatedByUser.push(item.value);
        }
      }
      this.selectedCarriers = [];
      this.loadCompanies();
      this.ShowCompleteOption = false;
      this.searchInputValue = "";
      this.searchValue = "";
      this.startIndex = 0;
      this.first = 0;
      this.endDate = new Date();
      this.taskDateChange = new Date();
      this.taskDate = Utils.getDateInFormat(this.endDate);
      this.sortOrder = false;
      this.sortColumn = "taskDate";
      this.selectedShowCompleteOption = "0";
      this.selectedTeamMember = [];
      this.selectedTeamMember.push("" + UserContext.userId);
      this.isTaskServiceApiCall = false;
      await this.loadTasks();
      // this.isInitialLoad = true;
    }
  };
  @action searchIntervals = async (text: string) => {
    this.searchInputValue = text;
    if (text) {
      this.searchValue = text;
    } else {
      this.searchValue = undefined;
      this.startIndex = 0;
      await this.loadTasks();
    }
  };
  @action noRecordsFound() {
    this.totalRecords = 0;
    this.TaskList.values = [];
  }
  @action setSortOrder() {
    this.sortOrder = !this.sortOrder;
    this.startIndex = 0;
    this.first = 0;
  }
  @action setSortColumn(column: string) {
    this.sortColumn = column;
  }
  @action onPage(firstIndex: number, rows: number) {
    this.rows = rows;
    this.first = firstIndex;
    this.startIndex = firstIndex / this.rows;
    this.loadTasks();
  }
  @action setSelectDate = async (value: Date | Date[] | undefined) => {
    // Reset the Index when date change
    this.startIndex = 0;
    this.first = 0;
    this.sortColumn = "taskDate";
    this.sortOrder = false;
    if (value instanceof Date) {
      this.endDate = value;
    } else {
      this.endDate = undefined;
    }
    this.taskDateChange = value;
    this.taskDate = Utils.getDateInFormat(this.taskDateChange);
  };
  @action loadDropdowns = async (
    isIncludeInactiveUserCheckboxCall: boolean
  ) => {
    if (UserContext.userId && UserContext.userId > 0) {
      let result = await TaskStore.getAllTaskLookups(
        UserContext.userId,
        this.showInactiveUsers
      );

      if (result) {
        if (!isIncludeInactiveUserCheckboxCall) {
          this.teamMemberList.values = [];
          if (result.teamUsers !== null && result.teamUsers !== undefined) {
            Utils.mapListItemsToDropdown(
              result.teamUsers ? result.teamUsers : [],
              this.teamMemberList,
              ""
            );
            this.selectedTeamMember = [];
            this.selectedTeamMember.push("" + UserContext.userId);
          }
        }

        //Load CreatedByUsers
        if (result.users !== null && result.users !== undefined) {
          this.createdByUserList.values = [];
          this.selectedCreatedByUser = [];
          Utils.mapListItemsToDropdown(
            result.users ? result.users : [],
            this.createdByUserList,
            ""
          );
          this.selectedCreatedByUser =
            this.createdByUserList.values.length > 0
              ? this.createdByUserList.values.map((i: any) => i.value)
              : [];
        }
      }
    }
  };
  @action loadTasks = async () => {
    if (UserContext.userId && UserContext.userId > 0) {
      this.taskCompleteSelection = [];
      this.isLoading = true;
      try {
        var usrId = UserContext.userId;
        //if(this.selectedTeamMember.length > 0)
        //{
        //    usrId = parseInt(this.selectedTeamMember);
        //}
        var status: boolean | undefined = undefined;
        if (this.selectedShowCompleteOption != "")
          status = this.selectedShowCompleteOption === "1";

        var userTasks = await TaskStore.getUserTasks(
          usrId,
          status,
          this.taskDateChange,
          this.searchValue,
          this.selectedTeamMember,
          this.selectedCreatedByUser,
          this.selectedCarriers,
          this.startIndex,
          this.rows,
          this.sortColumn,
          this.sortOrder
        );

        //  var usertasks = await taskstore.getusertasks(usrid,status,
        //  this.taskDateChange, this.searchValue, this.selectedTeamMember, this.selectedCreatedByUser, this.startIndex, this.rows, this.sortColumn, this.sortOrder)

        if (userTasks != null) {
          if (userTasks.data) {
            if (this.selectedShowCompleteOption === "1" || this.IsShowAll)
              this.ShowCompleteOption = true;
            else this.ShowCompleteOption = false;

            this.ShowAllOption = this.IsShowAll;

            this.totalRecords = userTasks.recordCount
              ? userTasks.recordCount
              : 0;
            this.TaskList.values = userTasks.data;
          } else {
            this.noRecordsFound();
          }

          this.isTaskServiceApiCall = true;
        }
      } catch (e) {
        console.log(e);
        //  IMASLog.log('exception from store: ' + e.value)
        this.noRecordsFound();
        this.isTaskServiceApiCall = true;
      }
      this.isLoading = false;
    }
  };

  @action downloadReport = async (result: ReportFile, name: string) => {
    if (result) {
      await fetch(`data:${result.contentType};base64,${result.data}`)
        .then((response) => response.blob())
        .then((myBlob) => {
          var link = document.createElement("a");
          var url = window.URL.createObjectURL(myBlob);
          link.href = url;
          link.download = name;
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
          this.isTaskServiceApiCall = true;
        });
    }
  };

  @action setMultipleCompleteTaskConfirmation(value: boolean) {
    this.isMultipleCompleteTaskConfirm = value;
  }

  @action generateReport = async () => {
    try {
      if (this.isTaskServiceApiCall) {
        var usrId = this.userId;
        this.isLoading = true;
        this.isTaskServiceApiCall = false;

        var status: boolean | undefined = undefined;
        if (this.selectedShowCompleteOption != "")
          status = this.selectedShowCompleteOption === "1";

        let result = await TaskStore.downloadUserTasks(
          usrId,
          status,
          this.taskDate,
          this.searchValue,
          this.selectedTeamMember,
          this.selectedCreatedByUser,
          this.selectedCarriers
        );
        this.downloadReport(result, "User Tasks");
        this.isLoading = false;
      }
    } catch (error) {
      IMASLog.log("exception while dowloading user tasks.");
    }
  };

  @action setSelectedTask(taskId: number) {
    this.taskId = taskId;
  }
  @action onDeleteTaskClick = async () => {
    try {
      if (this.isTaskServiceApiCall) {
        //this.isTaskServiceApiCall = false;
        await TaskStore.deleteTask(this.taskId);
        setTimeout(async () => {
          this.setDeleteTaskConfirmation(false);
          await this.loadTasks();
        }, Utils.timeDelay_Delete());
      }
      this.isTaskServiceApiCall = true;
    } catch (error) {
      this.isTaskServiceApiCall = true;
      IMASLog.log("exception while deleting task.");
    }
  };

  @action onCompleteTaskClick = async () => {
    try {
      await TaskStore.completeTaskByTaskId({ id: this.taskId, complete: true });
      setTimeout(async () => {
        this.setCompleteTaskConfirmation(false);
        await this.loadTasks();
      }, Utils.timeDelay_Delete());
    } catch (error) {
      IMASLog.log("exception while completing task.");
    }
  };
  @action onMultipleCompleteTaskClick = async () => {
    try {
      let tasks: any = [];
      this.taskCompleteSelection &&
        this.taskCompleteSelection.forEach((element: any) => {
          tasks.push(parseInt(element.id));
        });

      await TaskStore.completeTask({ id: tasks });
      setTimeout(async () => {
        this.setMultipleCompleteTaskConfirmation(false);
        this.taskCompleteSelection = [];
        await this.loadTasks();
      }, Utils.timeDelay_Delete());
    } catch (error) {
      IMASLog.log("exception while deleting task.");
    }
  };

  @action setDeleteTaskConfirmation(value: boolean) {
    this.isDeleteTaskConfirm = value;
  }
  @action setCompleteTaskConfirmation(value: boolean) {
    this.isCompleteTaskConfirm = value;
  }
  compare = (a: any, b: any) => {
    // Use toUpperCase() to ignore character casing
    const nameA = a.carrierName.toUpperCase();
    const nameB = b.carrierName.toUpperCase();

    let comparison = 0;
    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }
    return comparison;
  };
  @action setSelectedStatus(value: string) {
    this.selectedStatus = value;
    this.loadTasks();
  }
  @action downloadDocumentLink = async (docId: number, filename: string) => {
    this.isLoading = true;
    try {
      var result = await DocumentStore.downloadFiles(docId, 0, true);
      if (result != null) {
        if (result.data) {
          Utils.downloadDocumentFile(
            result.data,
            result.fileName !== null && result.fileName !== undefined
              ? result.fileName
              : filename,
            result.headers ? result.headers["content-type"] : result.data.type
          );
        }
      }
    } catch (e) {
      IMASLog.log("exception while download/view the task document.");
    }
    this.isLoading = false;
  };

  @action setTaskCompleteSelection(taskList: any) {
    if (Array.isArray(taskList)) {
      let list = taskList.filter((item) => {
        return !item.complete && !item.profileAssociation;
      });

      this.taskCompleteSelection = list;
    }
  }
}
