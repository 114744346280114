import { action, observable } from "mobx";
import CommissionStore from "../../../../store/CommissionStore";
import ViewModel from "../../../../infrastructure/ViewModel";
import RouteList from "../../../../infrastructure/RouteList";

export class ProcessCommissionsDownloadFileViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  Route = async (currentRoute: RouteList): Promise<void> => {};

  @observable title: string = "";
  @observable isLoading: boolean = false;
  @observable isMessgeVisible: boolean = false;
  @observable response: any;
  @observable filename: string = "";

  @action Load = async () => {    
    this.filename = "";
    let path = window.location.pathname;
    let paths = path.split("/");
    let filename = paths[paths.length - 1];

    // let filename = file.split(".")[0]

    this.filename = filename;
    await this.downloadDocumentLink(filename ? filename : "");
  };

  @action callFromView = async () => {
    await this.downloadDocumentLink(this.filename ? this.filename : "");
  };

  @action downloadDocumentLink = async (doc: string) => {
    this.isLoading = true;
    this.isMessgeVisible = false;
    this.response = {};
    try {
    
      let result = await CommissionStore.downloadFile(doc);    
      if (result != null) {
        if (result.data) {
          this.downloadDocument(result.data,"Statement Result.xlsx");
        } else {
          if (result.message && result.message !== null) {
            var responseModel = {
              status: 400,
              title: result.message,
              errors: { "": [] },
            };
            this.response = JSON.stringify(responseModel);
            this.isMessgeVisible = true;
          }
        }
      }
    } catch (e) {
      this.isMessgeVisible = true;
      this.response = e.response;
    }
    this.isLoading = false;
  };

  @action downloadDocument = (base64: string,filename:string) => {
    setTimeout(() => {
      var link = document.createElement("a");
      link.download = `${filename}`;
      link.href = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + base64;
      link.textContent = "Download File";
      link.click();
      document.body.appendChild(link);
    }, 0);
  };
}
