import { observable, action } from "mobx";
import Collection from "../../infrastructure/CollectionHelper";
import ProducerSearchStore from "./../../store/ProducerSearchStore";
import { AgentListModel } from "../../services/ProducerSearchService";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import { routes } from "../../router";
import RouteParamDetails from "../../infrastructure/RouteParamDetails";
import { AgentSearch } from "../../infrastructure/enum/Common";
import { AgentSearchByMapViewModel } from "../maps/AgentSearchByMapViewModel";
import Utils from "../../infrastructure/Utils";
import {
  AgentSearchListener,
  TopHeaderSearchViewModel,
} from "../TopHeaderSearchViewModel";
import { Permission } from "../../infrastructure/enum/Permission";
import UserContext, { AgentSearchUserProperties } from "../../infrastructure/UserContext";

export class AgentsViewModel implements ViewModel, AgentSearchListener {
  updateAgentSearch(): void {   
    this.loadAgentsFromSearch();
  }
  get CanClose(): boolean {
    return true;
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }  
  @observable TopHeaderSearchViewModel: TopHeaderSearchViewModel | undefined;
  @action setHeaderSearchViewModel(
    viewModel: TopHeaderSearchViewModel | undefined
  ) {
    this.TopHeaderSearchViewModel = viewModel;
  }
  @action Load = async () => { 
    if (this.TopHeaderSearchViewModel) {
      this.TopHeaderSearchViewModel.setListener(this);
    }
    if ((!Utils.hasUserPermission(Permission.ViewAgentSSN) && !Utils.hasUserPermission(Permission.EditAgentSSN))) {
      var res = this.searchTypeList.find(x => x.label === "SSN");
      if (res) {
        this.searchTypeList.forEach((item, index) => {
          if (item === res) this.searchTypeList.splice(index, 1);
        });
      }
    }

    if (!Utils.hasUserPermission(Permission.ViewAgentTIN) && !Utils.hasUserPermission(Permission.EditAgentTIN)) {
      var res = this.searchTypeList.find(x => x.label === "TIN");
      if (res) {
        this.searchTypeList.forEach((item, index) => {
          if (item === res) this.searchTypeList.splice(index, 1);
        });
      }
    }    
  await this.loadAgentsFromSearch();
  };

  Route = async (currentRoute: RouteList): Promise<void> => { };
  @observable
  AgentSearchByMapViewModel: AgentSearchByMapViewModel =
    new AgentSearchByMapViewModel();
  @observable AgentsList = new Collection<AgentListModel>();
  @observable isRowClicked: boolean = false;
  @observable isSearchFilterChanged: boolean = false;
  @observable searchValue: string = "";
  @observable searchInputValue: string = "";
  @observable searchType: string = "6";
  @observable searchTypeList = [
    { label: "NPN", value: AgentSearch.Npn },
    { label: "Last Name", value: AgentSearch.LastName },
    { label: "First Name", value: AgentSearch.FirstName },
    { label: "Writing Number #", value: AgentSearch.Agent },
    { label: "Agent ID", value: AgentSearch.AgentId },
    { label: "Contact Options", value: AgentSearch.ContactOptions },
    { label: "DBA/Agency Name", value: AgentSearch.Dba },
    { label: "Email", value: AgentSearch.Email },
    { label: "Phone", value: AgentSearch.Phone },
    { label: "SSN", value: AgentSearch.Ssn },
    { label: "TIN", value: AgentSearch.Tin }
  ];
  @observable first: number = 0;
  @observable totalRecords: number = 0;
  @observable rows: number = 200;
  @observable startIndex: number = 0;
  @observable sortColumn: string = "lastname";
  @observable sortOrder: boolean = true;
  @observable validSsnError: string = "";

  @action loadAgentsFromSearch = async () => {       
    if (RouteParamDetails.agentSearchInput !== "") {
      this.noRecordsFound();
      this.searchInputValue = RouteParamDetails.agentSearchInput;
      this.searchValue = RouteParamDetails.agentSearchInput;
      this.searchType = RouteParamDetails.agentSearchType;
      await this.loadAgents();
    }
    else {     
      this.getAgentSearchUserProperties();
    }
   
  };
  formatPhoneNumber = (phoneNumberString: string) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return match[0];
    }
    return phoneNumberString;
  };
  
  removeDash = (input: string) => {
    return input.replace(/-/g, "");
  };
  @action loadAgents = async () => {
    try {
      if (this.searchType === AgentSearch.Phone) {
        this.searchValue = this.formatPhoneNumber(this.searchValue);
        this.searchValue = this.removeDash(this.searchValue);
      }
      if (
        this.searchType === AgentSearch.Ssn ||
        this.searchType === AgentSearch.Tin
      ) {
        this.searchValue = this.searchValue.replace(/-/g, "").trim();
        if (this.searchValue.length < 3) {
          this.validSsnError = "Please enter minimum 3 digits";
          this.noRecordsFound();
          return;
        } else {
          this.validSsnError = "";
        }
      }
      var searchTypeParam = this.searchType;
      if (this.searchType === AgentSearch.LastName) {
        searchTypeParam = Utils.getAgentSearchType(this.searchValue).toString();
      }
      let result = await ProducerSearchStore.getAgentList(
        +searchTypeParam,
        this.searchValue,
        this.startIndex,
        this.rows,
        this.sortColumn,
        this.sortOrder
      );
      if (result !== null) {
        if (result.recordCount) {
          if (result.data) {
            this.AgentsList.values = result.data;
            this.totalRecords = result.recordCount;
            if (
              result.recordCount === 1 &&
              Utils.hasUserPermission(Permission.ViewAgent)
            ) {
              this.showDetails(result.data[0].id, result.data[0].licenseId);
            }
          }
          RouteParamDetails.agentSearchInput = "";
          RouteParamDetails.agentSearchType = "";
        } else {
          this.noRecordsFound();
        }
      } else {
        this.noRecordsFound();
      }
    } catch (error) {
      this.noRecordsFound();
    }
  };
  @action setSearchType(value: string) {
    if (value !== "3") {
      this.validSsnError = "";
    }
    this.searchType = value;
    this.isSearchFilterChanged = value == '10';
    this.setAgentSearchUserProperties();
  }

  @action noRecordsFound() {
    this.totalRecords = 0;
    this.AgentsList.values = [];
  }

  @action searchIntervals = (text: string) => {
    this.searchValue = text;
    this.searchInputValue = text;
    if (!text) {
      this.noRecordsFound();
    }
  };

  @action loadFromHome = async (text: string) => {
    this.searchInputValue = text;
    this.searchValue = text;
    await this.loadAgents();
  };

  @action goSearch = async () => {
    this.sortColumn = "lastname";
    this.sortOrder = true;
    this.isSearchFilterChanged = false;
    this.setAgentSearchUserProperties();
    await this.loadAgents();
  };

  @action setAgentSearchUserProperties = async () => {
    var searchHeaderType = await this.getHeaderSearchType();
    let host = Utils.getHost();
    var userId = UserContext.getUserId();
    let userProperties: AgentSearchUserProperties = {
      buName: host,
      userId: userId,
      searchType: this.searchType,
      searchHeaderType: searchHeaderType
    };
    var data = JSON.stringify(userProperties);
    localStorage.setItem('agentSearchUserProperties', data);
  };

  @action getHeaderSearchType = async () => {
    var result = "";
    const agentSearchUserProperties = localStorage.getItem("agentSearchUserProperties");
    if (agentSearchUserProperties != undefined && agentSearchUserProperties != null) {
        const userprop: AgentSearchUserProperties = JSON.parse(agentSearchUserProperties);
        result = userprop && userprop.searchHeaderType ? userprop.searchHeaderType : "6";
      }
    return result;
  }

  @action getAgentSearchUserProperties = async () => {
    let host = Utils.getHost();
    var userId= UserContext.getUserId();
    const agentSearchUserProperties = localStorage.getItem("agentSearchUserProperties");
    if (agentSearchUserProperties != undefined && agentSearchUserProperties != null) {
      const userprop: AgentSearchUserProperties = JSON.parse(agentSearchUserProperties);
      if (host == userprop.buName && userprop.userId == userId) {
        this.searchType = userprop.searchType || "6";
      }
      else{
        this.searchType =  "6";
      }
    }
  };


  @action onPage = async (firstIndex: number, rows: number) => {
    this.first = firstIndex;
    this.rows = rows;
    this.startIndex = firstIndex / this.rows;
    await this.loadAgents();
  }
  @action setSortOrder() {
    this.sortOrder = !this.sortOrder;
    this.startIndex = 0;
    this.first = 0;
  }
  @action setSortColumn(column: string) {
    this.sortColumn = column;
  }
  @action searchAgents = async (text: string) => {     
    this.isSearchFilterChanged = false;
    await this.loadAgents();
  };

  @action validateAgentId = (id: string | undefined) => {
    if (id) return id;
    else return "";
  };

  @action getCityState = (agent: AgentListModel) => {
    if (agent.city && agent.state) return agent.city + ", " + agent.state;
    else if (agent.city && !agent.state) return agent.city;
    else if (!agent.city && agent.state) return agent.state;
    else return "";
  };
  @action addAgent = async () => {
    routes.addAgent.push();
  };
  @action showMaps = async () => {
    await Utils.getApiKey();
    routes.mapsView.push();
  };
  @action showDetails = async (
    agentId: number | undefined,
    contractId: number | undefined
  ) => {
    sessionStorage.setItem("createlog", "true");
    if (this.searchType === AgentSearch.Agent && !this.isSearchFilterChanged) {
      routes.contractDetails.push({
        agentId: "" + agentId,
        contractId: contractId ? contractId : 0,
      });
    } else {
      routes.agentDetails.push({
        agentId: "" + agentId,
      });
    }
  };
  @action showDetailsInNewTab = async (
    agentId: number | undefined,
    contractId: number | undefined
  ) => {
    if (this.searchType === AgentSearch.Agent) {
      Utils.openInNewTab(`/agents/${agentId}/contracts/details/${contractId}`);
    } else {
      Utils.openInNewTab("/agents/" + agentId);
    }
  };
}
